@import "colours.less";
@import "positioning.less";

.SearchResults {
		max-width: @max-content-width;
		margin: 1em auto;
		position: relative;
		padding: 2em;

		.search-page-description {
			margin: auto;
			max-width: 550px; 
			text-align: center;
		}
}

.num-results {
	color: @primary-blue;
	margin-bottom: 10px;
}

.unready-results {
	margin: 1em;
	border-bottom: 1px dashed @colour-01;
}

.SearchResult {
	position: relative;
	// Make columns full-height
	display: flex;
	// Override Bootstrap negative margins on rows
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 2em;
	box-shadow: none;
	
	.recommended-tab {
		position: absolute;
		bottom: 100%;
		z-index: 10;
		padding: 0.25em 2em;
		color: #81bee8;
		font-size: 120%;
		&:before {
			content: '';
			position: absolute;
			transform: perspective(150px) rotateX(30deg);
			transform-origin: bottom left;
			z-index: -2;
			background: white;
			top: -5px;
			right: 0;
			bottom: -1px;
			left: 0;
		}
		.recommended-icon {
			height: 1em;
			position: absolute;
			left: 0.5em;
			bottom: 0.5em;
		}
	}

	a, a:hover {
		text-decoration: none;
	}
	
	.logo {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		margin-top: 1.25em;

		.charity-logo {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
		.charity-logo-placeholder {
			font-size: 175%;
			&.long-name {
				font-size: 150%;
			}
		}
	}
	.text-summary {
		.name {
			margin-right: 0.3em;
		}
		.description, .description:hover {
			color: #262741
		}
	}
	.searchpg-flex-grow {
		flex: 1 0 33.333333%;
		max-width: 50%;
	}
	.impact {
		border-left: solid 1px @light-grey;
		h4 {
			font-size: 14px;
			font-weight: bold;
			font-family: @standard-text-font;
			color: @dark-grey;
		}
		.impact-summary {
			margin-top: 0.5em;
			font-size: 16px;
			display: flex;
			align-items: center;
			.impact-count, .money .amount, .currency-symbol {
				color: @donation-number-fg;
				//size and weight set locally to match figma as not reused
				font-size: 24px;
				font-weight: 800;
			}
			.impact-calculator-text {
				padding: 0px 16px;
			}
		}
		.click-through-action {
			margin-right: 24px;
			display: inline-block;
		}
	} // ./impact
	.read-more {
		float: right;
		position: relative;
		bottom: 0.5em;
		right: 0.5em;
		margin-top: 0.5em;
		text-transform: lowercase;
		font-size: 120%;
		background-color: #eec694;
		padding: 0.25em 7em 0.25em 0.5em;
		.read-more-caret {
			width: 1em;
			position: absolute;
			right: 0.5em
		}
	} // ./read-more
} // ./SearchResult

.FeaturedSidebar.affix-top {
	position: static;
	margin-top: 3vh;
}

.FeaturedSidebar.affix {
	position: fixed;
	top: 4vh;
}

.SearchForm {
	padding: 3em 5em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	max-width: @max-content-width;
	margin: auto;
	h2 {
		color: white;
	}
}

.SearchPage {
	.gradient {
		height: 25vh;
		background: linear-gradient(135deg, #79D579 0%, #005484 100%);
		margin-right: -15px;
		margin-left: -15px;
	}
	
	.worldImage {
		background-image: url('/img/world.png');
		background-size: cover;
		background-position: center;
		margin: auto;
		height: 100%;
	}
}

.search-pager {
	text-align: center;
	line-height: 3em;
	.pager-button {
		width: 2em;
		height: 2em;
		background-color: #292a33;
		display: inline-block;
		margin-right: 0.25em;
		border-radius: 0.25em;
		color: white;
		font-size: 125%;
		text-align: center;
		line-height: 2em;
		font-weight: bold;
		cursor: pointer;
	
		&.current-page {
			background-color: white;
			color: #292a33;
			cursor: normal;
		}
	}
}


.featured-charities-header {
	font-size: 1em;
	color: @colour-03;
	border-bottom: 2px solid @colour-01;
}

.btn-showall {
	background-color: @colour-02;
	color: @colour-05;
}


.sogive-search {
	width: 100% !important;
}

.sogive-search-box {
	.input-group {
		border: 1px solid #757575;
		border-radius: 4px;
	}
	input, button {
		font-size: 150% ;
		border: none;
		outline: 0;
	}
	button {
		margin: -1px; // this avoids the input-group border appearing around this button
		border-radius: 2px;
	}
}

.sogive-search-box .input-group-text {
	background-color: white;
	border: none;
	border-radius: 0px;
}

.field-clear-button {
	position: absolute;
	right: 60px;
	top: 10px;
	color: grey;
	z-index: 10;
	font-size: 2em;
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
	.SearchForm {
		.extend-content-to-sides(@bs-column-padding);
		padding: 1em;
	}
	.SearchResults {
		.extend-content-to-sides(@bs-column-padding);
		padding: 0em;

		position: relative;
		margin-top: 50px;
		padding: 2em;

		.results-list {
			padding: 2em;
		}

		.top-tab {
			width: 100%;
		}

		.num-results {
			margin-left: 2em;
		}

		.results-list {
			padding: 1em;
			margin-left: -2em;
			margin-right: -2em;
		}

		.SearchResult {
			.charity-logo-placeholder {
				font-size: 100%;
				&.long-name {
					font-size: 100%;
				}
			}
		}
	}

} // ./mobile screen



