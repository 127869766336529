@import "colours.less";

.ImpactCalculator {
	width: 100%;
}

@media screen and (max-width: 760px) {
	.inputs {
		border-right: none !important;
	}
	.comments {
		text-align: justify;
	}
	.charity-name-div p {
		text-align: justify;
	}
	.donation-impact {
		.donation-input {
			position: relative;
			right: 0;
		}
		
		.will-fund {
			display: none;
		}
	}
} // mobile media


.donation-impact {
	background-color: white;
	color: @charity-page-impact-calculator-text;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;

	/* Appear in front of the social share buttons */
	position: relative;
	z-index: 10;

	/* Columns populated with only position: absolute elements don't naturally have height - so when they stack on mobile they collide */
	.left-column, .right-column {
		height: 200px;
	}

	
		button.donation-up, button.donation-down {
			padding: 0;
			border: 5px solid @donation-amt-fg;
			border-radius: 50%;
			background-color: @donation-amt-fg;
			color: white;
			height: 28px;
			width: 28px;
			font-size: 28px;
			line-height: 15px;
			font-weight: bold;
			&:focus {
				outline: none;
			}
		}

	.donation-input, .donation-output {
		height: 200px;
	}

	.form-group {
		margin-bottom: 0;
	}
			.input-group .input-group-prepend {
				// blanket nuke Bootstrap 4's button styling rules on children
				* {
					padding: 0;
					border: none;
					border-radius: 0;
					background-color: transparent;
					font-size: 100%;
					font-weight: 800;
				}
				.input-group-text {
					color: @donation-number-fg;
				}
			}
			.input-group .form-control {
				width: 3em;
				padding-left: 0;
				color: @donation-number-fg;
			}
			.form-control, .input-group-prepend {
				border: none;
				border-radius: 0;
				box-shadow: none;
				//font-size and weight set here to match figma - bold & 28px - specific to this widget for emphasis
				font-size: 2em;
				font-weight: 800;
				height: inherit;
			}

	/* The output column - weird margins and padding on left to make room for the arrow graphic between the columns */
		.output-number {
			//font-size and weight set here to match figma - bold & 28px
			font-size: 2em;
			font-weight: 800;
			color: @donation-number-fg;
		}
}

// TODO the shadow on the donation row. This line is too wide, and doesn't work with the image.
// .donation-io-row .left-column, .donation-io-row .right-column {
// 	box-shadow: 0 30px 0 @dropshadow-colour;
// }


.amount-btn {
	width: 30px;
	height: 30px;
	padding: 6px 0;
	font-size: 12px;
	line-height: 1.428571429;
	border-radius: 15px;
}

.amount-btn.btn-primary {
	background-color: @colour-04 !important;
	border-color: @colour-04 !important;
}

.amount-btn:focus {
	background-color: @colour-04;
}

.ImpactCalculator {
	.impact {
		.impact-text {
			text-align: center;
		}
		.impact-text > span {
			display: block;
		}
		.impact-units-amount {
			font-size: 1.75em;
			margin-bottom: 0;
		}
	}
}

.giftAid {
	border-bottom: 1px solid @colour-01;
}

.no-padding {
	padding: 0 !important;
}

.social-media-btn {
	border: none;
	height: auto;
}

.twitter-btn {
	color: @color-twitter;
}
// ?? is this needed??
.twitter-btn:hover {
	color: @color-twitter;
}
.facebook-btn {
	color: 	@color-facebook;
}
.facebook-btn:hover {
	color: @color-facebook;
}