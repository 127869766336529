
// Maximum width for page content according to Brian's figma designs
@max-content-width: 1120px;

// FIXME Document this!

@navbar-height: 64px;
@bs-column-padding: 15px;

@band-1-height: 30px;
@band-2-height: 50px;
@band-3-height: 64px;

.extend-bg-to-sides(@marginwidth) {
	margin-left: -@marginwidth;
	margin-right: -@marginwidth;
	padding-left: @marginwidth;
	padding-right: @marginwidth
}

.extend-content-to-sides(@marginwidth) {
	margin-left: -@marginwidth;
	margin-right: -@marginwidth;
}