
.BSCarousel {
	height: 100%;
	.carousel, .carousel-inner, .carousel-item {
		height: 100%;
	}
	// keep the controls in the carousel -- use padding instead to avoid them
	// .carousel-control-next {
	// 	right: -13%;
	// }
	// .carousel-control-prev {
	// 	left: -13%;
	// }
	.carousel-control-next-icon {
		background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/></svg>");
	}
	.carousel-control-prev-icon {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
	}
}