
@import "colours.less";

.footer {
	border-top: 1px solid #999;
	background: #333;
	color: white;

	a {
		color: @gl-light-blue;
	}

	font-size: 11pt;
	line-height: 1;

	// make sure the footer is never too skinny
	min-height: 2em;
	padding: 0.5em;
}