

/**

FIXME refactor and clean this up -- this is a confusing use of css.
If size-specific parts are needed, document why with a comment.

*/

@media only screen and (max-width : 768px) {
	div#mainDiv > div > div.container.avoid-navbar > div#event.page > div.col-md-8.col-md-offset-2 > h2 { text-align: center;}
}

@media only screen and (max-width : 768px) {
	div#mainDiv > div > div.container.avoid-navbar > div#register.page > div > div.Wizard > div.WizardProgressWidget { display: none; }
}

@media only screen and (max-width : 768px) {
	div#mainDiv > div > div.container.avoid-navbar > div#register.page > div > div.Wizard > div.WizardStage > div > div.ticket-group { max-width: 100%; }
}

@media only screen and (max-width : 768px) {
	div#mainDiv > div > div.container.avoid-navbar > div#register.page > div > div.Wizard > div.WizardStage > div > div.ticket-group > ul.ticket-group-types > li.ticket-type > div.decoration{ display: none; }
}

@media only screen and (max-width : 768px) {
	div#mainDiv > div > div.container.avoid-navbar > div#register.page > div > div.Wizard > div.WizardStage > div.invoice > table.invoice-table { max-width: 90%; }
}

@media only screen and (max-width : 768px) {
	div#mainDiv > div > div.container.avoid-navbar > div#register.page > div > div.Wizard > div.WizardStage > div > div.invoice > table.invoice-table { max-width: 90%; }
}

@media only screen and (max-width : 768px) {
	div#mainDiv > div > div.container.avoid-navbar > div#register.page > div > div.Wizard > div.WizardStage > div > div > div.AttendeeDetails { max-width: 90%; }
}

@media only screen and (max-width : 768px) {
	div#mainDiv > div > div.container.avoid-navbar > div#register.page > div > div.Wizard > div.WizardStage > div > div.SearchResults { margin-left: 0px; margin-right: 0px; }
}

@media only screen and (max-width : 768px) {
	div#mainDiv > div > div.container.avoid-navbar > div#register.page > div > div.Wizard > div.WizardStage > div > div.SearchResults > div.results-list > div.SearchResult.row.recommended > a.logo.col-md-2.col-xs-4 { display: none; }
}

@media only screen and (max-width : 768px) {
	div#mainDiv > div > div.container.avoid-navbar > div#register.page > div > div.Wizard > div.WizardStage > div > div.SearchResults > div.results-list > div.SearchResult.row.recommended > div.impact.col-md-6.hidden-xs { display: block!important; max-width: 50%; }
}

@media only screen and (max-width : 768px) {
	div#mainDiv > div > div.container.avoid-navbar > div#register.page > div > div.Wizard > div.WizardStage > div > div.SearchResults > div.results-list > div.SearchResult.row.recommended > div.impact.col-md-6.hidden-xs > div.impact-summary { display: none; }
}

@media only screen and (max-width : 768px) {
	div#mainDiv > div > div.container.avoid-navbar > div#register.page > div > div.Wizard > div.WizardStage > div > div.SearchResults > div.results-list > div.SearchResult.row.recommended > div.impact.col-md-6.hidden-xs > div.impact-detail { display: none; }
}

@media only screen and (max-width : 768px) {
	div#mainDiv > div > div.container.avoid-navbar > div#register.page > div > div.Wizard > div.WizardStage > div > div.SearchResults > div.results-list > div.SearchResult.row.recommended > div.impact.col-md-6.hidden-xs > div.read-more.btn-default { max-width: 25%; float: left; }
}

@media only screen and (max-width : 768px) {
	div#mainDiv > div > div.container.avoid-navbar > div#register.page > div > div.Wizard > div.WizardStage > div > div.SearchResults > div.results-list > div.SearchResult.row.recommended > div.impact.col-md-6.hidden-xs > button.read-more.btn.btn-default { max-width: 25%; float: left; }
}

@media only screen and (max-width : 768px) {
	div#mainDiv > div > div.container.avoid-navbar > div#register.page > div > div.Wizard > div.WizardStage > div > div.SearchResults > div.results-list > div.SearchResult.row.recommended > a.text-summary.col-md-4.col-xs-8 { min-width: 44% }
}	

@media only screen and (max-width : 768px) {
	div#mainDiv > div > div.container.avoid-navbar > div#register.page > div > div.Wizard > div.WizardStage > div.ConfirmedTicketList > div.clear > div.padded-block > center > a.btn.btn-primary.btn-lg { max-width: 100%; word-wrap: normal; white-space: normal; }
}

@media only screen and (max-width : 768px) {
	div#mainDiv > div > div.container.avoid-navbar > div#register.page > div > div.Wizard > div.WizardStage > div.ConfirmedTicketList > div.clear > h3 { max-width: 100%; text-align: center; word-wrap: normal; white-space: normal; }
}