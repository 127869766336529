// @import 'Page.less';
@import 'colours.less';

@stage-colour: #80bde7;
@completed-colour: #005;

@marker-radius: 15px;

.WizardProgressWidget {
	display: flex;
	justify-content: center;
	align-items: center;

	.Stage {
		position: relative;
		display: inline-block;

		/* evenly spaced */
		flex-grow: 1;
		flex-basis: 0;

		h5 {
			font-size: 18px;
			transition: opacity 0.5s;
		}

		// container for marker and line - use as a position/sizing reference
		.graphic {
			position: relative
		}

		.marker {
			position: relative;
			left: 50%;
			margin-left: -@marker-radius;
			width: (@marker-radius * 2);
			height: (@marker-radius * 2);
			border-radius: 50%;
			z-index: 1;
		}

		.line {
			position: absolute;
			top: @marker-radius;
			width: 100%;
			height: 0;
			outline: 2px solid grey;
			z-index: 0;
		}

		// Run the line between the dots - don't extend to the edges
		&:first-child, &:last-child {
			.line { width: 50%; }
		}
		&:first-child .line {
			left: 50%;
		}

		// Mark previous stages as clickable to signify they can be jumped to
		&.complete {
			cursor: pointer;
		}

		// Labels: Above by default, below for currently active stage
		.below {
			opacity: 0;
		}
		&.active {
			.above { opacity: 0; }
			.below { opacity: 1; }
		}

		// Colours: Pale blue default, dark blue for active/complete
		color: @stage-colour;
		.marker { background-color: @stage-colour; }
		&.complete, &.active {
			color: @completed-colour;
			.marker { background-color: @completed-colour; }
		}
	}
} // ./WizardProgressWidget

// Copied from sogive-app Page.less
@item-max-width: 650px;
.padded-block {
	max-width: @item-max-width;
	margin-left: auto;
	margin-right: auto;
}

.WizardStage {
	min-height: 50vh;

	.nav-buttons {
		// .padded-block;
		margin-top:10px;
		// /* using floats, so we need to make the widget big enough */
		// min-height: 60px;
	}
	.nav-buttons-top {
		margin-top:0;
		border-bottom: 1px solid #333;
		padding-bottom: 1rem;
		margin-bottom: 2rem;
	}
}
