
/* palette from Isabel */
@grey: #3F4151;
@grey-lighter: #787a85;
@grey-lightest: #c5c6cb;
@orange: #DF9232;
@orange-lighter: #eab87a;
@orange-lightest: #f5dec1;
@pale-blue: #81bee8;
@paler-blue: #add4f0;
@palest-blue: #d9ebf8;
@green: #00A55E; 
//colours from Brian
@dark-turquoise: #39917F;
@dark-grey: #414141;
@light-grey: #F0F0F0;
@light-beige: #F8F8F8;
@primary-blue: #3E60C6;

/* Corrado's original palette TODO refactor out, prefer semantic names */
@colour-01: #F04B47;
@colour-02: #A49F79;
@colour-03: #262741;
@colour-04: #323257;
@colour-05: #FFFFFF;

@SogiveBrandYellow: #f60;

@navbarcolour: @SogiveBrandYellow;

@bold-text-colour: @orange;

@donation-amt-bg: @orange-lighter;
@donation-amt-input-bg: #FDE8BA;
@donation-amt-fg: @dark-turquoise;
@donation-number-fg: @dark-turquoise;
@donation-button-bg: @green;

@charity-page-greybg: #E4E7EB;
@charity-page-greyline: #C8CBD1;

@charity-page-summary-text-colour: @dark-grey;
@charity-page-impact-calculator-text: @dark-grey;
@header-section-title-colour: @dark-grey;
@charity-card-title-colour: @dark-grey;

@grey-border-colour: #D7DBDC;

// NB: others are done in base/style/colours.less
@color-twitter: #4099ff;
@color-facebook: #3b5998;

@primary-bg: @paler-blue;
@default-bg: #6e707b;
@light-fg: white;
@dark-fg: #414352;

@default-button-colour: #deb686;
@darken-default-button-colour: darken(@default-button-colour, 10%);
@dropshadow-colour: rgba(0,0,0,0.2);
// NB: warning and danger buttons, which we don't really use, draw colour from Bootstrap defaults

@header-line-colour: #f9ecdd;
@type-bg-colour: #dfe0e2;
@type-line-colour: #cdced2;
@masthead-bg: #676974;

@progress-bar-done: @pale-blue;
@donation-date: @grey-lightest;

.text-gold {color: #D6AF36;}
.text-silver {color:#A7A7AD;}
.text-bronze {color: #A77044;}

