
@import "colours.less";

.share-social-buttons {
	float: right;
	z-index: 9; /* Tuck under the donation-impact div */
	height: 75px; /* Make room for the "pop down on hover" effect */
	a {
		/* Space them out and tuck them up under the column bottom */
		margin: -10px 5px 5px 5px;
		display: inline-block;
		font-size: 40px;
		line-height: 60px;
		width: 60px;
		height: 60px;
		border: none;
		border-radius: 50%;
		padding: 0;
		color: white;
		text-align: center;
		cursor: pointer;
		&.share-social-facebook {
			background-color: royalblue;
		}
		&.share-social-twitter {
			background-color: skyblue;
		}
		&.share-social-email {
			background-color: @donation-amt-bg;
		}
		&:hover {
			transform: translateY(15px);
		}
		transition: transform 0.25s ease;
	}
}
