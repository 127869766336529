@import "colours.less";

.ListShares{
    width: 100%;
}

.EmailListing{
    margin-bottom: 10px;
}

.EmailListing p{
	word-wrap: break-word;
}
