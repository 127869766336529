.faq-row-wrapper {
	background-color: unset !important;
}

.faq-row {
	background-color: white;

	margin: 1em 0;
	padding: 0 !important;
	border: 1px solid #757575 !important;
	border-radius: 10px;
}

.row-title {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.row-title-text {
	margin: 0 1rem;
}

.row-content-text {
	margin: 1rem;
}

.row-title.expanding {
	background-color: @dark-turquoise;
	border-bottom: 1px solid #757575;
	transition: background-color .1s linear;
	transition: border-bottom .1s linear;

	.icon-wrapper svg {
		fill: white !important;
		transition: fill .1s linear;
	}
	.row-title-text {
		color: white;
		transition: color .1s linear;
	}
}

.row-title.expanded {
	background-color: @dark-turquoise;
	border-bottom: 1px solid #757575;

	.icon-wrapper svg {
		fill: white !important;
	}
	.row-title-text {
		color: white;
	}
}

.row-title.closing {
	background-color: white;
	transition: background-color .1s linear;
}