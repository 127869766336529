.dropdown-menu {
	/* fix issue with menu going off the right, seen Feb 2022 */
	left: unset;
	right: 0;

	.nav-link {
		color: #0056b3 !important;
		//line-height: 20px;
	}
	.nav-link:hover {
		text-decoration: underline;
	}
}
