/** SoGive Fonts: Let's standardise on a few fonts and sizes here.
*/

html {
	font-size: 18px; 
}

@font-face {
	font-family: 'Roboto';
	src: url('/fonts/Roboto-Regular.ttf');
}
h1, .h1, h2, .h2, h3, .h3 {
	font-family: Roboto;
}

/**
For a smaller version of a style, use <small> or .small
*/

