@import "colours.less";

/* spinner */
.loader-box {
	padding-top: 50px;
}
.loader {
	border-top: 16px solid @gl-red;
	border-right: 16px solid @gl-dark-blue;
	border-bottom: 16px solid @gl-orange;
	border-left: 16px solid @gl-light-blue;

	border-radius: 50%;
	width: 120px;
	height: 120px;
	animation: spin 2s linear infinite;
}
// ./loader

// Spin animation
.spinning {
	animation: spin 3s infinite linear;
}

@keyframes spin {
	from { transform: scale(1) rotate(0deg); }
	to { transform: scale(1) rotate(360deg); }
}

// TODO doc: why / for-what-devices is this needed?? 
@-webkit-keyframes spin {
	from { -webkit-transform: rotate(0deg); }
	to { -webkit-transform: rotate(360deg); }
}

.slide {
	transition: transform 1s ease;
}

.slide.up {
	transform: translateY(-100%);
}