// Styling for the SoGive app

@import "../js/base/style/main-base.less";
@import "./bs3-4polyfill.less";

@import "sogive-fonts.less";
@import "colours.less";
@import "fonts.less";
@import "positioning.less";
@import "buttons.less";

@import "Faq.less";
@import "navbar.less";

.avoid-navbar {
    margin-top: @navbar-height + 1;
}

.navbar-brand, .navbar-nav li a {
    //line-height: @navbar-height;   // Is this needed? May 2020
    height: @navbar-height;
	padding-top: 20px;
	display: block;
}

/* Override the above so the account/logout menu looks normal */
.dropdown li a {
	line-height: initial !important;
	height: auto !important;
}

.navbar .navbar-nav .nav-item {
	padding: 15px;
}

.navbar-search-widget {
	padding-top: 15px;
	.sogive-search-box {
		padding-top: 10px!important;
		padding-bottom: 10px!important;
	}
}

.navbar .navbar-nav .nav-item .nav-link {
	color: @dark-grey;
	font-size: 16px;
	font-weight: bold
}

.navbar .navbar-nav .nav-item.active .nav-link {
	color: @primary-blue;
}

.messagebar {
	margin-top:5px;
}

@import "animation.less";

// Page specific stuff
@import "Page.less";
@import "EditCharityPage.less";
@import "SearchPage.less";
@import "CharityPage.less";
@import "ImpactCalculator.less";
@import "DonationWizard.less";
@import "FundRaiserPage.less";
@import "RegisterPage.less";
@import "CardShopPage.less";
@import "SocialShare.less";
@import "LoginWidget.less";
@import "footer.less";
@import "EventPage.less";
@import "ShareWidget.less";
@import "HomePage.less";
@import "AboutPage.less";
@import "WebsiteCards.less"; 
@import "CareersPage.less";
@import "AnalysisPage.less";

/* https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */
.flexbox-1row {
	display: flex;
	flex-wrap: nowrap;
	justify-content: center; // flex-start | flex-end | center | space-between | space-around;
}
.flexbox {
	display: flex;
}

// Let any singleton column be centred in its row
.container .row .col-center {
	float: none;
	margin: 0 auto;
}

body {
	font-family: 'Nunito Sans', sans-serif;
	color: @colour-03;
}

// BS3 legacy support
.well {
	min-height: 10px;
	padding: 9px;
	margin-bottom: 10px;
	background-color: #f5f5f5;
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
	box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
}

//for consistent border styling across the app
.std-border {
	border: 1px solid @grey-border-colour;
	border-radius: 4px;
	box-sizing: border-box;
}

.std-padding {
	padding: 1.25em;
}

.std-box-shadow {
	box-shadow: 0px 3px 6px rgba(81, 81, 81, 0.125);
}

// Banner shared with Methodology, About Us and Blog Page
.gradient-banner {
    height: 15vh;
    background: linear-gradient(135deg, #79D579 0%, #005484 100%);
    // margin-right: -15px;
    // margin-left: -15px;

    .worldImage {
        background-image: url('/img/world.png');
        background-size: cover;
        background-position: center;
        margin: auto;
        height: 100%;
    
        h1 {
            color: white;
			text-transform: unset !important;
        }
    }
}

// New kind of btn
.btn-white {
	background-color: white;
	text-align: center;
  border-color: @primary-blue;
	color: @primary-blue;
	padding: 1em 1.3em;
}

.btn-white:hover {
	background-color: @primary-blue;
	color: white;
	border-color: white;
}

// BlogPage
.blog-iframe {
	width: 100%;
	height: 80vh;
}