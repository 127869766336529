@import "colours.less";

// Actually ... Bootstrap have done quite a lot of work to make their buttons buttony
// We want to cover the states like :active and :hover
// We can copy stuff from Bootstrap
// But for now, let's just use vanilla

// // Using #mainDiv or .modal-dialog to increase specificity of selectors to beat BS
#mainDiv, .modal-dialog {
	
	.btn { // double BS normal rounding -- why??
		border-radius: 8px;
	}
	.btn-lg {
		border-radius: 12px;
	}
	/* switch off rounding for input appends; copy paste from BS 'cos we changed border-radius above */
	.input-group>.input-group-append>.btn, 
	.input-group>.input-group-prepend:first-child>.btn:not(:first-child), 
	.input-group>.input-group-prepend:not(:first-child)>.btn {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border: 1px solid #ced4da; // copy form control's colours. Otherwise appends look odd!
	}

	.btn-round {
		// Border-radius can't overlap, so it gets clamped to the largest non-overlapping value - giving us semicircular ends
		border-radius: 100vw;
	}

	.btn-primary {
		background: @primary-blue;
		border-radius: 4px; // why less rounding than normal??
		padding: 8px 24px;
		font-weight: bold;
		&:hover {
			background: white;
			color: @primary-blue;
		}
	}

	.btn-outline-primary {
		color: @primary-blue;
		border-color: @primary-blue;
		border-radius: 4px;
		padding: 8px 24px;
		font-weight: bold;
		&:hover {
			background: @primary-blue;
			color: white;
		}
	}



	.btn-square {
		background-color: @default-button-colour;
		box-shadow: 2px 2px 2px @dropshadow-colour;
		border-radius: 0px !important;
		border: none;
		&:hover {
			background-color: @darken-default-button-colour;
		}	
		&:active {
			// box-shadow: inset 0 3px 5px rgba(0,0,0,.125)
			box-shadow: inset 0 2px 5px @dropshadow-colour;
		}
	}

	.btn-group {
		display: flex;
		box-shadow: 0px 2px 2px @dropshadow-colour;
	}
	.btn-group > .btn:not(:active) { //:first-child:not(:last-child):not(.dropdown-toggle) {
		// no right shadow
		box-shadow: none; // 0px 2px 2px rgba(0,0,0,0.2);
	}
}

/* small print text within a button */	
.btn-smallprint {
	font-size: 85%; // same as BS .small
	max-width: 150px;
	line-height: 100%;	
	white-space: normal; // BS prevents word-wrap otherwise
}
