@import "colours.less";

.login-guts {
	.login-divs {
		.login-email, .login-social {
			background: @charity-page-greybg;
			padding-top: 1rem;
		}
	}
}
.switch-verb {
	background: @charity-page-greybg;
}
