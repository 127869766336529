@import "colours.less";
@import "positioning.less";

@standard-text-font: 'Nunito Sans';

.header-centred {
	text-transform: none; // without this bootstrap makes headers all caps
	font-family: @standard-text-font;
	font-weight: 800;
	font-size: 28px;
	color: @dark-turquoise;
	text-align: center;
}

.header-title {
	text-transform: capitalize;
	font-family: @standard-text-font;
	font-weight: 800;
	font-size: 32px;
	color: @charity-page-summary-text-colour;
	margin-top: 0;
	margin-bottom: 0;
	.extend-bg-to-sides(@bs-column-padding);
	line-height: @band-2-height;
}

.header-section-title {
	text-transform: none; // without this bootstrap makes headers all caps
	font-family: @standard-text-font;
	color: @header-section-title-colour;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
}

.charity-card-title {
	font-family: @standard-text-font;
	color: @charity-card-title-colour;
	font-weight: bold;
	font-size: 21px;
}

.div-section-text {
	font-family: @standard-text-font;
	color: @dark-grey;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 22px;
}

.div-section-larger-text {
	font-size: 18px;
}
	