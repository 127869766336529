#newsletter-card, #impact-rating-card {
	overflow: hidden;
	// margin: 0 -1em;

	h3 {
		font-weight: bold;
		text-transform: unset;
	}

	.press-caption {
		font-size: 1.8rem;
		color: grey;
	}

}

#contact-card #message {
	width: 100%;
	height: 10vh;
}

.alert-hidden{
	display: none;
}