// Hides an item by sliding it to one side while fading out (and shows by the reverse)
@import "colours.less";

.slide-hide {
	transition: opacity 0.25s, transform 0.25s, visibility 0.25s;
}

.hide-left, .hide-right, .hide-down {
	position: absolute !important;
	margin-top: inherit;
	top: 0;
	opacity: 0;
	visibility: hidden;
}

.hide-left {
	transform: translateX(-30%);
}

.hide-right {
	transform: translateX(30%);
}

.hide-down {
	transform: translateY(100%);
}


/* spinner */
.loader-box {
	padding-top: 50px;
}
.loader {
	border-top: 16px solid @orange;
	border-right: 16px solid @green;
	border-bottom: 16px solid @pale-blue;
	border-left: 16px solid @grey;

    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}
// ./loader

// Spin animation
.spinning {
	animation: spin 3s infinite linear;
	//-webkit-animation: spin2 3s infinite linear;
}

@keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
}

@-webkit-keyframes spin2 {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}