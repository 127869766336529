/* footer styles
-------------------------------------------------- */
@import "colours.less";

html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height + 48px */
  margin-bottom: 185px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 250px;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 137px;
  background-color: @light-beige;
  color: @dark-grey;
  overflow: hidden;
  padding: 32px 65px 18px;
  font-size: 14px;
  border: none;

  a {
    color: @dark-turquoise;
  }

  .footer-toplinks {
    font-size: 16px;
    margin-bottom: 16px;
    a {
      margin-right: 16px;
    }
  }

  .footer-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 12px;
    padding: 32px 5px 18px;
    height: auto;
    max-height: 202px;

    .footer-toplinks {
      font-size: 14px;
    }
  
    .footer-grid {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }
}
