@import "colours.less";

.DropZone {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	// width: 120px;
	// height: 100px;
	border: 2px dashed @grey;
	border-radius: 5px;
	cursor: pointer;
	// font-weight: bold; why?
	&.disabled {
		border-color: #aaa;
	}
}

/* have the save button always visible */
.SavePublishDeleteEtc {
	position: fixed;
	bottom:0;
	padding:10px;
	color:white;
	background: #333;
	width:100%;
	z-index: 100;
}

.panel-heading.btn-link {
	cursor: pointer;
}

/* The loading spinner*/
.loader-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.spinner-box {
		width: 120px;
		height: 120px;
		animation: spin 2s infinite linear;
		border-radius: 50%;
		overflow: hidden;
	}

	&.inline {
		flex-direction: row;
		.spinner-box {
			width: 50px;
			height: 50px;
		}
		.loader-text {
			margin-left: 1em;
		}
	}
}

// @deprecated use animation.less instead
@keyframes spin {
	from { transform: rotate(0deg); }
	to { transform: rotate(360deg); }
}